// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle'

// import Swiper styles
import 'swiper/css'

class Collections {
    init() {
        const collectionsSlider = new Swiper('.collections__slider > .swiper', {
            grabCursor: true,
            watchSlidesProgress: true,
            loop: true,
            loopedSlides: 8,
            slidesPerView: 'auto',
            centeredSlides: true,
            /*autoplay: {
                delay: 3000,
            },*/
            on: {
                progress(collectionsSlider) {
                    const scaleStep = 0.2;
                    const zIndexMax = collectionsSlider.slides.length;
                    for (let i = 0; i < collectionsSlider.slides.length; i += 1) {
                        const slideEl = collectionsSlider.slides[i];
                        const slideProgress = collectionsSlider.slides[i].progress;
                        const absProgress = Math.abs(slideProgress);
                        let modify = 1;
                        if (absProgress > 1) {
                            modify = (absProgress - 1) * 0.16 + 1;
                        }
                        const opacityEls = slideEl.querySelectorAll(
                            '.collections__item-opacity',
                        );
                        const translate = `${slideProgress * modify * 50}%`;
                        const scale = 1 - absProgress * scaleStep;
                        const zIndex = zIndexMax - Math.abs(Math.round(slideProgress));
                        slideEl.style.transform = `translateX(${translate}) scale(${scale})`;
                        slideEl.style.zIndex = zIndex;
                        if (absProgress > 3) {
                            slideEl.style.opacity = 0;
                        } else {
                            slideEl.style.opacity = 1;
                        }

                        opacityEls.forEach((opacityEl) => {
                            opacityEl.style.opacity = 1 - absProgress / 4;
                        });
                    }
                },
                setTransition(collectionsSlider, duration) {
                    for (let i = 0; i < collectionsSlider.slides.length; i += 1) {
                        const slideEl = collectionsSlider.slides[i];
                        const opacityEls = slideEl.querySelectorAll(
                            '.collections__item-opacity',
                        );
                        slideEl.style.transitionDuration = `${duration}ms`;
                        opacityEls.forEach((opacityEl) => {
                            opacityEl.style.transitionDuration = `${duration}ms`;
                        });
                    }
                },
            },
        })
    }
}
export default Collections