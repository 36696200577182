class Animation {
    init() {
        let _this = this
        document.addEventListener('DOMContentLoaded', function () {
            let clientHeight = document.documentElement.clientHeight,
                topOffset = window.pageYOffset

            _this.fadeIn(topOffset, clientHeight)

            window.addEventListener('scroll', () => {
                _this.fadeIn(topOffset, clientHeight)
            })
        })
    }

    fadeIn(topOffset, clientHeight) {
        let animatedItems = document.querySelectorAll('.fade-in')

        animatedItems.forEach(item => {
            let itemTopOffset = item.getBoundingClientRect().top

            if (!item.classList.contains('visible')) {
                if(itemTopOffset <= clientHeight - 200){
                    item.classList.add('visible')

                }
            }
        })
    }
}

export default Animation