class Lang {
    refs = {
        switcher: '',
        current: '',
        items: []
    }

    /**
     * Init lang switcher
     * */
    init() {
        this.refs.switcher = document.querySelector('.lang');
        this.refs.current = this.refs.switcher.querySelector('.lang__current');
        this.refs.items = this.refs.switcher.querySelectorAll('.lang__dropdown-item');

        this.dropdown()
        this.changeLang()
    }

    /**
     * Open/close dropdown on click
     * */
    dropdown() {
        document.addEventListener('click', (e) => {
            let classList = e.target.classList

            if (classList.contains('lang__current')) {
                this.openDropdown()
            } else {
                this.closeDropdown()
            }
        })
    }

    /**
     * Change active language on click
     * */
    changeLang() {
        this.refs.items.forEach(item => {
            item.addEventListener('click', () => {
                this.refs.items.forEach(langItem => {
                    langItem.classList.remove('lang__dropdown-item--selected')
                })

                this.refs.current.innerHTML = item.querySelector('span').innerHTML
                item.classList.add('lang__dropdown-item--selected')
            })
        })
    }

    /**
     * Opening dropdown
     * */
    openDropdown() {
        this.refs.switcher.classList.add('opened')
    }

    /**
     * Closing dropdown
     * */
    closeDropdown() {
        this.refs.switcher.classList.remove('opened')
    }
}

export default Lang