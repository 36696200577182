class Hero {
    init() {
        const heroSteps = document.querySelectorAll('.hero__steps-item')

        heroSteps.forEach(step => {
            let stepNumber = step.getAttribute('data-step'),
                _this = this

            if (Number(stepNumber) === 1) {
                _this.makeStepActive(step)
            } else if(Number(stepNumber) === 2) {
                _this.makeStepActive(step, 3600)
            } else if(Number(stepNumber) === 3) {
                _this.makeStepActive(step, 7200)
            }
        })

        document.addEventListener('DOMContentLoaded', function(){
            //const heroVideo =  document.getElementById('hero_video')

            //heroVideo.setAttribute('src', '/src/video/home.mp4')
            //heroVideo.innerHTML = '<source src="/src/video/home.mp4" type="video/mp4">'
            //heroVideo.play()
        })

    }

    makeStepActive(step, delay = 0) {
        let _this = this
        const heroVideo = document.querySelector('.hero__video')

        _this.activeStepInterval(step, delay, heroVideo)
        setInterval(function () {
            _this.activeStepInterval(step, delay, heroVideo)
        }, 10800)
    }

    activeStepInterval(step, delay, video) {
        const heroStepsContainer = document.querySelector('.hero__steps')
        let stepNumber = step.getAttribute('data-step'),
            _this = this

        setTimeout(function () {
            step.classList.add('active')
            video.classList.add('step-' + stepNumber)

            //_this.showHeroText(stepNumber)

            heroStepsContainer.setAttribute('data-step', stepNumber)
        }, delay)
        setTimeout(function () {
            step.classList.remove('active')
            video.classList.remove('step-' + stepNumber)

            //_this.showHeroText(stepNumber)
        }, delay + 3600)
    }

    showHeroText(stepNumber) {
        const heroTextBlocks = document.querySelectorAll('.hero__content-text')
        heroTextBlocks.forEach(item => {
            let number = item.getAttribute('data-step')

            if (Number(number) === Number(stepNumber)) {
                item.classList.add('visible')
            } else {
                item.classList.remove('visible')
            }
        })
    }
}

export default Hero