// import Bootstrap
import 'bootstrap';

// import template styles
import './scss/main.scss'

import Lang from './js/components/lang/lang'
const lang = new Lang
lang.init()

import Hero from './js/components/hero/hero'
const hero = new Hero()
hero.init()

import Collections from './js/components/collections/collections'
const collections = new Collections()
collections.init()

import Animation from './js/components/animation/animation'
const animation = new Animation()
animation.init()